import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const TagsList = ({tags}) => (
    <ul className="tags-list">
        {tags.map(({name, id, slug}) => (
            <li className="button" key={id}>
                <Link to={`/tag/${slug}/`}>
                    {name}
                </Link>
            </li>
        ))}
    </ul>
);

TagsList.propTypes = {
    tags: PropTypes.array.isRequired,
};

export default TagsList;