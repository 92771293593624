import React from "react";
import { graphql, Link } from "gatsby";
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import TagsList from '../components/tags-list';
import PostMeta from '../components/post-meta';
import BlogImage from '../components/blog-image';
import Prism from 'prismjs';
import Disqus from 'disqus-react';

require('prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.js');
require('prismjs/themes/prism-dark.css');
require('prismjs/components/prism-markup-templating');
require(`prismjs/components/prism-php.js`);


class PostTemplate extends React.Component {

    componentDidMount() {
        Prism.highlightAll();
    }

    render() {
        const post           = this.props.data.wordpressPost;
        const featured_image = `${this.props.data.site.siteMetadata.siteUrl}${post.featured_media.localFile.childImageSharp.original.src}`;
        const yoast_meta     = post.yoast_meta;
        const pageContext    = this.props.pageContext;
        const prevPost       = this.props.data.prevPost;
        const nextPost       = this.props.data.nextPost;

        const disqusShortname = this.props.data.site.siteMetadata.disqusShortName;
        const disqusConfig    = {
            url: post.link,
            identifier: post.wordpress_id,
            title: post.title,
        };

        const linkNext = pageContext.nextSlug ? pageContext.nextSlug : '';
        const linkPrev = pageContext.prevSlug ? pageContext.prevSlug : '';

        //TODO: Replace it with more robust solution
        //very hacky way to restore the proper uploads URL before we find a way to serve them other way
        const content = post.content.replace(/https:\/\/lowgravity.pl\/core\/uploads/g, 'https://api.lowgravity.pl/core/uploads');

        return (
            <Layout>
                <PageHeader
                    heading={post.title}
                    pageTitle={post.yoast_meta.yoast_wpseo_title}
                    seoMetaData={{...yoast_meta, featured_image}}
                />
                <div className="blog-single-component generic-content-component">
                    <div className="component-wrapper">
                        <div className="blog-content">
                            <div className="blog-single-header">
                                    <BlogImage original_src={post.featured_media.localFile.childImageSharp.original.src}
                                            background_color={post.acf.featured_image_background_color} />
                                <div className="blog-single-info">
                                    <PostMeta date={post.date} author={post.author} />
                                    <TagsList tags={post.tags} />
                                </div>
                            </div>
                            <div className="blog-single-content" dangerouslySetInnerHTML={{__html: content}}></div>
                            <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
                        </div>
                        <div className="related-posts">
                            {linkPrev === '' ? null : (
                                <div className="prev-post">
                                    <h4>Previous post</h4>
                                    <Link to={linkPrev}>
                                        <div className="thumb-wrapper">
                                            <BlogImage original_src={prevPost.featured_media.localFile.childImageSharp.original.src}
                                            background_color={prevPost.acf.featured_image_background_color} />
                                        </div>
                                        <p dangerouslySetInnerHTML={{__html: this.props.data.prevPost.title}}></p>
                                    </Link>
                                </div>
                            )}
                            {linkNext === '' ? null : (
                                <div className="next-post">
                                    <h4>Next post</h4>
                                    <Link to={linkNext}>
                                        <div className="thumb-wrapper">
                                            <BlogImage original_src={nextPost.featured_media.localFile.childImageSharp.original.src}
                                            background_color={nextPost.acf.featured_image_background_color} />
                                        </div>
                                        <p dangerouslySetInnerHTML={{__html: this.props.data.nextPost.title}}></p>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
};

export default PostTemplate

export const pageQuery = graphql`
    query($id: String!, $prevID: String, $nextID: String) {
        wordpressPost(id: { eq: $id }) {
            title
            content
            link
            wordpress_id
            author {
                name
                link
            }
            date(formatString: "Do [of] MMMM, YYYY")
            tags {
                id
                name
                slug
            }
            featured_media {
                localFile {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
            }
            acf {
                featured_image_background_color
            }
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
                yoast_wpseo_canonical
            }
        }
        prevPost: wordpressPost(id: { eq: $prevID }) {
            title
            featured_media {
                localFile {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
            }
            acf {
                featured_image_background_color
            }
        }
        nextPost: wordpressPost(id: { eq: $nextID }) {
            title
            featured_media {
                localFile {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
            }
            acf {
                featured_image_background_color
            }
        }
        site {
            siteMetadata {
                disqusShortName
                siteUrl
            }
        }
    }
`;
